[v-cloak] { display:none; }

*{font-family:'Roboto', sans-serif;padding:0;margin:0;box-sizing:border-box;}

body{
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color:#f4f6fc;
}

.link_jump {
    text-decoration: underline !important;
    color: #9f1f1f !important;
    font-weight: 500;
}

p{
    color:#11162ebf;
    line-height:25px;
}

.error_alert {
    p {
        color: #e00015;
        font-weight: 500;
    }
}

.underlined{
    text-decoration:underline;
}

.uppercase{
    text-transform:uppercase;
}

h1{
    font-weight:500;
    font-size:45px;
}

h2{
    font-size: 36px;
    font-weight: 700;
    line-height: 48px;
}

strong{
    color:#11162e;
}

.white-text{
    color:#fff;
}

.blue-btn{
    padding:15px 20px;
    color:#fff;
    background-color:#00a5b4;
    text-decoration:none;
    border-radius:5px;
}

.line-btn{
    padding:15px 20px;
    color:#00a5b4;
    background-color:transparent;
    text-decoration:none;
    border-radius:5px;
    border: 1px solid #c7c7c7;
}

.read-more-btn{
    color:#00a5b4;
    text-decoration:None;
    font-weight:600;
}

header{
    width:100%;
    position:absolute;
    z-index:2;
    .header-inner{
        padding:20px 10px;
        width:100%;
        max-width:1400px;
        margin:auto;
        display:flex;
        justify-content:flex-start;
        align-items:center;
        .toggle-nav-open{
            display:none;
        }
        .header-logo{
            font-size: 2em;
            font-weight: 600;
            a {
                &:hover {
                    text-decoration: none;
                }
            }
            span {
                color: #333;
                border-bottom: 1px solid #333;
            }
            img{
                max-width:180px;
            }
        }
        .header-nav{
            width:100%;
            flex:1;
            .toggle-nav-close{
                display:none;
            }
            ul{
                list-style:none;
                display:flex;
                flex-direction:row;
                width:100%;
                max-width:500px;
                justify-content:space-evenly;
                margin-bottom: 0;
                a{
                    color:#11162e;
                    text-decoration:None;
                }
            }
        }
        .header-cta{
            margin-left:auto;
            display:flex;
            align-items:center;
            p{
                font-weight:600;
                margin-right:30px;
                margin-bottom:0;
                font-size:14px;
                display:flex;
                align-items:center;
                i{
                    font-size:20px;
                    margin-left:10px;
                }
            }
        }
    }
}

.step-3 {

    form{
        input,select{
            width:100%;
            height:65px;
            line-height:65px;
            background-color: #edf0fa;
            border-radius:4px;
            border:0px;
            outline:none;
            font-size:16px;
        }
        button{
            outline:none;
            border: none;
        }
        .form-check-inline {
            padding-left: 20px;
            font-size:16px;
            input {
                width: auto;
                height: auto;
            }
        }
    }

}

.home-hero{
    padding:100px 0px 0px 0px;
    .home-hero-inner{
        display:flex;
        align-items:center;
        justify-content:center;
        max-width:1400px;
        margin:auto;
        .home-hero-left{
            width:55%;
            display:flex;
            align-items:center;
            justify-content:center;
            flex-direction:column;
            .home-hero-left-inner{
                max-width:530px;
                h1{
                    font-weight:500;
                    font-size:45px;
                    margin-bottom:30px;
                    span{
                        font-weight:600;
                        border-bottom:4px solid #00a5b4;
                    }
                }
                p{
                    margin: 15px 0px;
                }
                .underlined{
                    text-decoration:underline;
                }
                form{
                    position:relative;
                    display:flex;
                    margin:20px 0px;
                    input{
                        width:80%;
                        height:45px;
                        background-color: #edf0fa;
                        border-radius:4px;
                        border:0px;
                        text-indent:15px;
                        outline:none;
                        font-size:16px;
                    }
                    button{
                        width:50px;
                        background-color:#00a5b4;
                        border:0px;
                        border-radius:0px 4px 4px 0px;
                        i{
                            color:#fff;
                            font-size:22px;
                        }
                    }
                }
                .gov-text{
                    display:flex;
                    align-items:center;
                    margin-top:40px;
                    img{
                        margin-right:20px;
                    }
                    p{
                        font-size:12px;
                        font-weight:600;
                        line-height:20px;
                    }
                }
            }
        }
        .home-hero-right{
            width:45%;
            display:flex;
            align-items:center;
            justify-content:center;
            padding:80px 0px 0px 0px;
            img{
                max-width:350px;
                position:relative;
                z-index:2;
            }
            .home-hero-right-background{
                background-color:#edecea;
                position:absolute;
                z-index:1;
                width:45%;
                max-height:699px;
                height:100%;
                right:0px;
                top:0px;
            }
        }
    }
}

.step-bar{
    background-color:#11162e;
    width:95%;
    margin-right:5%;
    .step-bar-inner{
        width:100%;
        max-width:1400px;
        margin:auto;
        display:flex;
        .step-bar-block{
            text-align:center;
            flex:1;
            display:flex;
            align-items:center;
            flex-direction:column;
            justify-content:center;
            padding:40px 20px;
            img{}
            strong{
                color:#fff;
                margin:20px 0px;
                font-weight:500;
            }
            p{
                color:#ffffff87;
                font-size:13px;
            }
        }
    }
}

.boiler-grant-full{
    background-color:#f4f6fc;
    .boiler-grant{
        width:95%;
        margin-right:10%;
        padding:80px 0px 0px 0px;
        .boiler-grant-inner{
            display:flex;
            width:100%;
            max-width:1400px;
            margin-left:auto;
            .boiler-grant-left{
                padding:40px;
                width:60%;
                p{
                    max-width:500px;
                    margin: 30px 0px;
                }
                .button-container{
                    display:flex;
                    margin-top:30px;
                    a{
                        margin-right:15px;
                    }
                }
            }
            .boiler-grant-right{
                display:flex;
                align-items:center;
                justify-content:flex-end;
                position:relative;
                width:40%;
                img{
                    width:100%;
                }
                .grant-points{
                    position:absolute;
                    background-color:#fff;
                    left:-100px;
                    bottom: 80px;
                    ul{
                        padding:10px;
                        list-style:none;
                        li{
                            padding:20px;
                            margin:0px 10px;
                            border-bottom:1px solid #f1f1f1;
                            i{
                                color:#f6c22a;
                                margin-right:10px;
                                font-size:25px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.faq{
    background-color:#f4f6fc;
    position:relative;
    z-index:3;
    .faq-inner{
        width:100%;
        max-width:1200px;
        margin:auto;
        position: relative;
        top: 80px;
        padding:0px 20px;
        .faq-top{
            display:flex;
            align-items:flex-end;
            justify-content:space-between;
            .faq-top-left{
                p{
                    margin-bottom:20px;
                }
            }
            .faq-top-right{

            }
        }
        .faq-bottom{
            display:flex;
            flex-wrap:wrap;
            justify-content:space-between;
            align-items:stretch;
            margin-top:20px;
            .faq-block{
                width:49%;
                background-color:#fff;
                margin:10px 0px;
                padding:40px;
                p{
                    margin:20px 0px;
                }
            }
        }
    }
}

.customers{
    background-color:#11162e;
    display:flex;
    align-items:center;
    justify-content:center;
    padding:250px 0px 200px 0px;
    position:relative;
    .customers-left{
        background-image:url(../img/customers-left.png);
        background-size:contain;
        background-repeat:no-repeat;
        background-position:center;
        position:absolute;
        width:30%;
        height:100%;
        z-index:1;
        left:0px;
        top:0px;
    }
    .customers-right{
        background-image:url(../img/customers-right.png);
        background-size:contain;
        background-repeat:no-repeat;
        background-position:center;
        position:absolute;
        width:30%;
        height:100%;
        z-index:1;
        right:0px;
        top:0px;
    }
    .customers-inner{
        max-width:500px;
        text-align:center;
        position:relative;
        z-index:2;
        h2{
            color:#fff;
            span{
                color:#00a5b4;
            }
        }
        p{
            color:#fff;
            margin:25px 0px;
        }
        a{
            display:table;
            margin:auto;
        }
    }
}

.qualify{
    display:flex;
    align-items:center;
    justify-content:center;
    padding:80px 0px 80px 0px;
    position:relative;
    background-color:#fff;
    .qualify-inner{
        max-width:500px;
        text-align:center;
        position:relative;
        z-index:2;
        h2{

        }
        p{
            margin:25px 0px;
        }
        a{
            display:table;
            margin:auto;
        }
    }
}

.link-boxes{
    background-color:#fff;
    .link-boxes-inner{
        width:100%;
        max-width:1200px;
        margin:auto;
        display:flex;
        align-items:center;
        justify-content:space-evenly;
        flex-wrap:wrap;
        .link-box{
            display:flex;
            width:22%;
            padding:0px 0px 15px 0px;
            text-decoration:none;
            margin-bottom:15px;
            border-bottom:2px solid #ececec;
            .link-image{
                width:40%;
                img{
                    width:100%;
                }
            }
            .link-text{
                width:60%;
                display:flex;
                flex-direction:column;
                justify-content:space-evenly;
                padding-left:15px;
                font-size:15px;
                .blue-text{
                    color:#00a5b4;
                }
            }
        }
    }
}

.postcode-bar{
    background-color:#fff;
    padding:50px 20px;
    .postcode-bar-inner{
        width:100%;
        max-width:1000px;
        margin:auto;
        display:flex;
        justify-content:space-evenly;
        align-items:center;
        .postcode-bar-left{
            width:60%;
            p{
                margin-top:15px;
                font-size:14px;
            }
        }
        .postcode-bar-right{
            width:40%;
            form{
                position:relative;
                display:flex;
                margin:20px 0px;
                input{
                    width:80%;
                    height:45px;
                    background-color: #edf0fa;
                    border-radius:4px;
                    border:0px;
                    text-indent:15px;
                    outline:none;
                    font-size:16px;
                }
                button{
                    width:50px;
                    background-color:#00a5b4;
                    border:0px;
                    border-radius:0px 4px 4px 0px;
                    i{
                        color:#fff;
                        font-size:20px;
                    }
                }
            }
        }
    }
}

.latest-news{
    padding:60px 20px;
    background-color:#f4f6fc;
    .latest-news-inner{
        width:100%;
        max-width:1200px;
        margin:auto;
        .latest-news-top{
            display:flex;
            justify-content:space-between;
            align-items:center;
            margin-bottom:20px;
        }
        .latest-news-bottom{
            display:flex;
            align-items:flex-start;
            justify-content:space-between;
            .latest-left{
                width:calc(50% - 50px);
                .latest-big{
                    .latest-big-content{
                        padding:20px 20px 20px 0px;
                        h3{
                            margin-bottom:20px;
                            font-size:27px;
                        }
                        .blue-link{
                            color:#00a5b4;
                            text-decoration:none;
                            font-weight:600;
                            margin-top:20px;
                            display:flex;
                        }
                        .uppercase{
                            text-transform:uppercase;
                            margin-bottom:20px;
                        }
                    }
                    .latest-big-image{
                        img{
                            width:100%;
                        }
                    }

                }
            }
            .latest-right{
                width:50%;
                display:flex;
                align-items:stretch;
                justify-content:space-between;
                flex-wrap:wrap;
                .latest-small{
                    width:calc(50% - 20px);
                    margin-bottom:40px;
                    .latest-small-image{
                        img{
                            width:100%;
                        }
                    }
                    .latest-small-content{
                        p{
                            font-size:13px;
                            margin:15px 0px;
                        }
                        strong{
                            line-height:25px;
                        }
                    }
                }
            }
        }
    }
}

footer{
    padding:80px 20px;
    background-color:#fff;
    .footer-inner{
        width:100%;
        max-width:1200px;
        margin:auto;
        .footer-top{
            display:flex;
            align-items:flex-start;
            justify-content:space-between;
            .footer-top-left{
                width: 50%;
                h1{
                    span{
                        font-weight:600;
                        border-bottom:4px solid #00a5b4;
                    }
                }
            }
            .footer-top-right{
                width: 50%;
                .button-container{
                    display:flex;
                    margin-top:20px;
                    justify-content:flex-start;
                    a{
                        margin-right:20px;
                    }
                }
            }
        }
        .footer-bottom{
            .footer-links{
                display:flex;
                justify-content:flex-start;
                align-items:center;
                margin-top:80px;
                ul{
                    display:flex;
                    list-style:none;
                    margin-bottom:0;
                    li{
                        margin-right:20px;
                        a{
                            text-decoration:none;
                            color:#11162e;
                        }
                    }
                }
                img{
                    max-width:200px;
                    margin-right:20px;
                }
            }
            p{
                margin-top:20px;
            }
        }
    }
}

.free-hero{
    padding:200px 20px 140px 20px;
    .free-hero-inner{
        text-align:center;
        max-width:500px;
        margin:auto;
        h1{
            margin-bottom:50px;
            display:block;
            span{
                font-weight:600;
                border-bottom:4px solid #00a5b4;
            }
        }
        form{
            position:relative;
            display:flex;
            margin:20px 0px;
            input{
                width:90%;
                height:45px;
                background-color: #edf0fa;
                border-radius:4px;
                border:0px;
                text-indent:15px;
                outline:none;
                font-size:16px;
            }
            button{
                width:50px;
                background-color:#00a5b4;
                border:0px;
                border-radius:0px 4px 4px 0px;
                i{
                    color:#fff;
                    font-size:22px;
                }
            }
        }
    }
}

.free-grant{
    background-color:#11162e;
    color:#fff;
    width:100%;
    .boiler-grant .boiler-grant-inner .boiler-grant-right{
        top:-150px;
    }
    p{
        color:#fff;
    }
}

.free-link-boxes{
    padding-bottom:80px;
}

.free-news{
    padding:80px 20px;
    .free-news-inner{
        width:100%;
        max-width:1200px;
        margin:auto;
        display:flex;
        justify-content:space-between;
        align-items:flex-start;
        .free-news-left{
            width:46%;
            img{
                width:100%;
            }
            h3{
                margin-top:20px;
                font-size:27px;
                line-height:40px;
            }
            p{
                margin:20px 0px;
            }
            a{
                display:table;
            }

        }
        .free-news-right{
            width:46%;
            img{
                width:100%;
            }
            h3{
                margin-top:20px;
                font-size:27px;
                line-height:40px;
            }
            p{
                margin:20px 0px;
            }
            a{
                display:table;
            }
        }
    }
}

.free-help{
    overflow:hidden;
    height:100%;
    width:100%;
    .free-help-inner{
        overflow:hidden;
        height:100%;
        width:100%;
        position:relative;
        .free-help-background{
            width:70%;
            height:100%;
            min-height:500px;
            display:block;
            background-image:url("../img/free-help.png"),linear-gradient(45deg, black,black);
            background-size:cover;
            background-position:center;
            z-index:1;
            position:relative;
        }
        .free-help-content{
            background-color: #fff;
            padding: 50px;
            position: absolute;
            right: 15%;
            z-index: 2;
            max-width: 550px;
            top: 80px;
            h3{
                font-size:27px;
                line-height:35px;
                margin:20px 0px;
            }
        }
    }
}

.free-postcode-bar{
    background-color:#11162e;
    h3,p{
        color:#fff;
    }
}

.free-qualify{
    background-color:#f4f6fc;
    .qualify-inner{
        max-width:600px;
    }
}

.sorry-hero{
    padding:200px 20px 80px 20px;
    .sorry-hero-inner{
        width:100%;
        max-width:1200px;
        margin:auto;
        display:block;
        align-items:flex-start;
        justify-content:space-between;

        .sorry-hero-left{
            width:100%;
            h1{
                font-weight:600;
                border-bottom:2px solid #00a5b4;
                margin-bottom:20px;
                padding-bottom:20px;
                span{
                    color:#00a5b4;
                }
            }
        }
        .sorry-hero-right{
            width:100%;
            .sorry-content{
                background-color:#fff;
                padding:20px;
                text-align: left;
                img {
                    width: 120px;
                    margin-bottom: 10px;
                }
                h3{
                    font-size:27px;
                    line-height:35px;
                    margin-bottom:20px;
                }
                p{
                    margin-bottom:20px;
                }
                ul{
                    list-style:none;
                    display:flex;
                    flex-wrap:wrap;

                    li{
                        display:flex;
                        align-items:center;
                        width:50%;
                        i{
                            color: #f6c22a;
                            margin-right:15px;
                            font-size:30px;
                        }
                        p{
                            margin:10px 0px;
                            font-size:13px;
                            font-weight:600;
                        }
                    }
                }
                a{
                    display:table;
                    margin-top:20px;
                }
            }
        }
    }
}

.form-steps{
    padding:200px 0px 80px 0px;
    .form-steps-inner{
        width:100%;
        max-width:1200px;
        margin:auto;
        display:flex;
        align-items:center;
        justify-content:space-between;
        padding:0px 20px;
        .step{
            flex:1;
            &:nth-child(1){
                .progress-bar{
                    border-radius:10px 0px 0px 10px;
                }
            }
            &:nth-child(3){
                .progress-bar{
                    border-radius:0px 10px 10px 0px;
                }
            }
            .progress-bar{
                background-color: #d2d2d2;
                height:7px;
                width:100%;
                margin-bottom:15px;
            }
            p{
                font-weight:500;
                color:#d2d2d2;
                text-align:center;
            }
        }
        .complete{
            .progress-bar{
                background-color:#00a5b4;
            }
            p{
                color:#00a5b4;
            }
        }
    }
}

.form-container{
    padding-bottom:60px;
    .form-inner{
        width:100%;
        max-width:1200px;
        margin:auto;
        padding:0px 20px;
        .step-1{

        }
        .step-intro{
            margin-bottom:20px;
            h1{
                font-weight:600;
                margin-bottom:20px;
            }
            strong{
                margin-bottom:20px;
                display:block;
            }
            p{
                margin-bottom:60px;
            }
            h3{
                font-size:27px;
            }
        }
    }
    .question{
        margin:30px 0px 20px 0px;
        p{
            font-weight: 500;
        }
        .click-help{
            color:#00a5b4;
            font-weight:500;
            margin-top:15px;
            display:block;
            cursor:pointer;
            &:hover {
                color: #00919e;
            }
        }
        .help-box{
            padding:30px;
            background-color: rgba(0, 165, 180, 0.09);
            margin:30px 0px;
            position:relative;
            max-width:500px;
            display:block;
            p{
                font-size:14px;
                margin-bottom:0;
                padding-bottom:0;
            }
            strong{
                margin-bottom:10px;
                display:block;
            }
            &:after{
                content:'';
                width:30px;
                height:50px;
                position:absolute;
                background-color: rgba(0, 165, 180, 0.09);
                left:20px;
                top:-50px;
                display:block;
                -webkit-clip-path: polygon(50% 56%, 0% 100%, 100% 100%);
                clip-path: polygon(50% 56%, 0% 100%, 100% 100%);
            }
        }
    }
    .half-block{
        width:50%;
    }
    .full-block{
        width:100%;
    }
    .form-next{
        margin-top:30px;
        padding: 15px 50px;
        color: #FFF;
        font-weight: 500;
        cursor: pointer;
        &:hover {
            color: #FFF;
            background-color: #00919e;
        }
    }
    .form-back{
        margin-top:30px;
        padding: 15px 50px;
        font-weight: 500;
        cursor: pointer;
    }
    .buttons{
        ul{
            list-style:none;
            display:flex;
            flex-wrap:wrap;
            justify-content:space-between;
            align-items:stretch;
            li{
                width:calc(50% - 10px);
                padding:15px 20px;
                display:flex;
                align-items:center;
                border:1px solid #d2d2d2;
                margin:5px;
                border-radius:4px;
                cursor: pointer;
                .check-box{
                    border-radius:50px;
                    min-width:30px;
                    min-height:30px;
                    border:1px solid #d2d2d2;
                    margin-right:15px;
                    display:flex;
                    align-items:center;
                    justify-content:center;
                    .selected{
                        min-width:18px;
                        min-height:18px;
                        display:block;
                        background-color:#00a5b4;
                        border-radius:50px;
                    }
                }
                p{
                    font-weight:500;
                    margin-bottom:0;
                }
            }
            .active{
                border:1px solid #00a5b4;
                .check-box{
                    border:1px solid #00a5b4;
                    .selected{
                        display:block;
                    }
                }
                p{
                    color:#00a5b4;
                }
            }
        }
    }


}

@media (max-width:1300px){
    .boiler-grant-full{
        .boiler-grant{
            .boiler-grant-inner{
                .boiler-grant-left{

                }
                .boiler-grant-right{
                    .grant-points{
                        left:-10px;
                        bottom:-10px;
                    }
                }
            }
        }
    }
}

@media (max-width:1200px){
    header{
        .header-inner{
            padding:20px 20px;
            .header-cta{
                p{
                    display:none;
                }
            }
        }
    }

}

@media (max-width:1000px){

    .home-hero{
        .home-hero-inner{
            flex-direction:column;
            .home-hero-left{
                width:100%;
                .home-hero-left-inner{
                    padding:40px 30px;
                    h1{
                        font-size:35px;
                    }
                    strong,p{
                        font-size:14px;
                    }
                    .underlined{
                        text-align:center;
                        span{
                            font-weight:600;
                        }
                    }
                    form{
                        input{
                            width:90%;
                            height:60px;
                        }
                    }
                }
            }
            .home-hero-right{
                width:100%;
                background-color:#e6e5e1;
                img{
                    margin:auto;
                    display:block;
                    max-width:350px;
                }
                .home-hero-right-background{
                    position:relative;
                    display:none;
                }
            }
        }
    }

    footer{
        .footer-inner{
            .footer-top{
                flex-direction:column;
                .footer-top-left{
                    width:100%;
                    margin-bottom: 30px;
                }
                .footer-top-right{
                    width:100%;
                }
            }
        }
    }
}

@media (max-width:800px){
    header{
        .header-inner{
            .toggle-nav-open{
                display:flex;
                align-items:center;
                justify-content:center;
                margin:0px 20px 10px 20px;
                i{
                    font-size:30px;
                }
            }
            .header-nav{
                order:1;
                position:fixed;
                background-color:#fff;
                height:100vh;
                width:300px;
                left:0px;
                top:0px;
                z-index:100;
                display:none;
                align-items:flex-start;
                justify-content:flex-start;
                flex-direction:column;
                padding:40px;
                .toggle-nav-close{
                    display:flex;
                    align-items:center;
                    justify-content:center;
                    margin-bottom:30px;
                    i{
                        font-size:40px;
                    }
                }
                ul{
                    flex-direction:column;
                    li{
                        border-bottom:2px solid #f3f3f3;
                        a{
                            font-size:22px;
                            padding:20px;
                            display:block;
                        }
                    }
                }
            }
            .header-cta{
                order:3;
            }
            .header-logo{
                img{
                    max-width:140px;
                }
            }
        }
    }

    .step-bar{
        width:100%;
        padding:50px 20px;
        .step-bar-inner{
            flex-direction:column;
            .step-bar-block{
                max-width:300px;
                margin:auto;
                padding:20px;
            }
        }
    }

    .boiler-grant-full{
        .boiler-grant{
            width:100%;
            padding:0px;
            .boiler-grant-inner{
                flex-direction:column;
                .boiler-grant-left{
                    width:100%;
                    .button-container{
                        flex-direction:column;
                        a{
                            text-align:center;
                            padding:20px 0px;
                            margin:5px 0px;
                        }
                    }
                }
                .boiler-grant-right{
                    width:100%;
                    padding:30px;
                    .grant-points{
                        left:20px;
                        bottom:-10px;
                    }
                }
            }
        }
    }

    .faq{
        .faq-inner{
            .faq-top{
                flex-direction:column;
                div{
                    width:100%;
                    a{
                        display:table;
                        width:100%;
                        text-align:center;
                        padding:20px;
                        margin-top:20px;
                    }
                }
            }
            .faq-bottom{
                flex-direction:column;
                .faq-block{
                    width:100%;
                }
            }
        }
    }

    .customers{
        flex-direction:column;
        padding: 140px 0px 0px 0px;
        .customers-left,.customers-right{
            display:none;
        }
        .mobile-customers{
            background-image:url('../img/mobile-customers.png');
            background-size:cover;
            width:100%;
            max-width:400px;
            height:400px;
        }
        .customers-inner{

        }
    }

    .qualify{
        padding:50px 0px;
        .qualify-inner{
            text-align:left;
            padding:0px 20px;
            h2{
                br{
                    display:none;
                }
            }
            a{
                width:100%;
                padding:20px;
                text-align:center;
            }
        }
    }

    .link-boxes{
        .link-boxes-inner{
            padding:0px 20px;
            .link-box{
                width:50%;
            }
        }
    }

    .postcode-bar{
        .postcode-bar-inner{
            flex-direction:column;
            .postcode-bar-left{
                width:100%;
                max-width:400px;
                h3{
                    font-size:30px;
                }
            }
            .postcode-bar-right{
                width:100%;
                max-width:400px;
                form{
                    input{
                        width:100%;
                    }
                }
            }

        }
    }

    .latest-news{
        .latest-news-inner{
            flex-direction:column;
            .latest-news-top{

            }
            .latest-news-bottom{
                flex-direction:column;
                .latest-left{
                    width:100%;
                }
                .latest-right{
                    width:100%;
                }
            }
        }
    }

    footer{
        .footer-inner{
            .footer-top{
                .footer-top-left{
                    h1{
                        font-size:35px;
                        br{
                            display:none;
                        }
                    }
                }
                .footer-top-right{
                    .button-container{
                        flex-direction:column;
                        a{
                            text-align:center;
                            padding:20px;
                            margin:10px;
                        }
                    }
                }
            }
            .footer-bottom{
                p{
                    text-align:center;
                }
                .footer-links{
                    img{
                        margin-bottom:20px;
                    }
                    ul{
                        flex-direction:column;
                        text-align:center;
                        li{
                            margin:10px;
                        }
                    }
                    flex-direction:column;
                }
            }
        }
    }

    .free-grant{
        .boiler-grant{
            .boiler-grant-inner{
                .boiler-grant-right{
                    top:0px;
                }
            }
        }
    }

    .free-news{
        .free-news-inner{
            flex-direction:column;
            .free-news-left{
                width:100%;
                margin:20px 0px;
            }
            .free-news-right{
                width:100%;
                margin:20px 0px;
            }
        }
    }

    .free-help{
        .free-help-inner{
            .free-help-background{
                width:100%;
                display:none;
            }
            .free-help-content{
                right:0px;
                top:0px;
                position:relative;
            }
        }
    }

    .sorry-hero{
        padding: 140px 20px 80px 20px;
        .sorry-hero-inner{
            flex-direction:column;
            .sorry-hero-left{
                width:100%;
                max-width:400px;
                margin:auto;
                margin-bottom:40px;
            }
            .sorry-hero-right{
                width:100%;
                .sorry-content{
                    text-align:left;
                    .sorry-points{
                        ul{
                            text-align: left;
                            flex-direction:column;
                            li{
                                width:100%;
                                display:flex;
                                justify-content:flex-start;
                            }
                        }
                    }
                    a{
                        width:100%;
                    }
                }
            }
        }
    }

    .form-steps{
        padding: 120px 0px 30px 0px;
    }

    .form-container{
        .form-inner{
            .half-block{
                width:100%;
            }
            .step-intro{
                h1{
                    font-size: 30px;
                }
            }
        }
    }
}

@media (max-width:600px){
    .link-boxes{
        .link-boxes-inner{
            padding:0px 20px;
            .link-box{
                width:100%;
                max-width:300px;
            }
        }
    }

    .latest-news{
        .latest-news-inner{
            flex-direction:column;
            .latest-news-top{

            }
            .latest-news-bottom{
                flex-direction:column;
                .latest-left{
                    width:100%;
                }
                .latest-right{
                    width:100%;
                    .latest-small{
                        width:100%;
                        display:flex;
                        align-items:center;
                        .latest-small-content{
                            padding:20px;
                        }
                    }
                }
            }
        }
    }

    .free-hero{
        padding: 200px 20px 50px 20px;
        .free-hero-inner{
            h1{
                br{
                    display:none;
                }
            }
            form{
                input{
                    width:100%;
                    height:60px;
                }
            }
        }
    }

    .form-steps{
        .form-steps-inner{
            .step{
                p{
                    font-size:11px;
                }
            }
        }
    }
    .form-container{
        .form-inner{
            .buttons{
                ul{
                    li{
                        width:100%;
                    }
                }
            }
        }
    }

    header{
        .header-inner{
            .header-cta{
                a{
                    font-size:14px;
                }
            }
        }
    }
}
